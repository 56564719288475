
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { connect } from 'react-redux';
import { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import Landing from '../components/Landing/Landing';
import Dashboard from '../components/Dashboard/Dashboard';
import Loader from '../components/Loader/Loader';
import getT from 'next-translate/getT';

function Home(props) {
  const {
    meta_title,
    meta_description,
    header,
    subHeader,
    login_button,
    signup_button,
    login_email_or_name_input,
    password_input,
    forget_password,
    isAuthenticated,
    gameSetup,
  } = props;

  return (
    <>
      {/* Meta and SEO */}
      <Head>
        <meta name="description" content={meta_description} />
        <title>{meta_title}</title>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function () {
                window.onpageshow = function(event) {
                  if (event.persisted) {
                    window.location.reload();
                  }
                }
              })();
            `,
          }}
        />
      </Head>

      {/* Google Tag Manager (GTAG) */}
      {process.env.NEXT_ENABLE_GTAG === 'true' && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}

      {/* Hotjar Tracking */}
      {process.env.NEXT_ENABLE_HOTJAR === 'true' && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1879307,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
      )}

      {/* Render Dashboard if Authenticated, otherwise Landing Page */}
      {isAuthenticated ? (
        gameSetup.dataLoaded ? <Dashboard gameSetup={gameSetup} /> : <Loader />
      ) : (
        <Landing
          textContent={{
            header,
            subHeader,
            login_button,
            signup_button,
            login_email_or_name_input,
            password_input,
            forget_password,
          }}
        />
      )}
    </>
  );
}

// Fetch Translations at Build Time
 async function _getStaticProps({ locale }) {
  const t = await getT(locale, 'meta');
  return {
    props: {
      meta_title: t('meta_title'),
      meta_description: t('meta_description'),
      header: t('header'),
      subHeader: t('subHeader'),
      login_button: t('login_button'),
      signup_button: t('signup_button'),
      login_email_or_name_input: t('login_email_or_name_input'),
      password_input: t('password_input'),
      forget_password: t('forget_password'),
    },
  };
}

// Map Redux State to Props
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  gameSetup: state.gameSetup,
});

export default connect(mapStateToProps)(Home);


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  
import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';
import { GetRulesLangKeys } from '../../helpers/GetRulesLangKeys';
import useTranslation from 'next-translate/useTranslation';
import { cdnLoader } from '../../config/cdnImageLoader.config';

const RulesStep = (props) => {
  const {
    availableRules,
    selectedGameMode,
    onRuleChange,
    selectedRules
  } = props

  const {t} = useTranslation()

  const ruleIsChosen = (ruleId) => {
    return selectedRules.indexOf(ruleId) >= 0
  }
  return (
    // <div className = {`${commonStyles.buttons_container} ${commonStyles.rules_container} ${selectedGameMode.value === 'solo' ? commonStyles.narrow : ''}`}>
    <div className = {`${commonStyles.buttons_container} ${commonStyles.rules_container} ${commonStyles.narrow}`}>
      <div className = {commonStyles.inner_container}>
        <div className = {`${commonStyles.button_wrapper} `}>
          <div className  = {`${commonStyles.button_container} ${commonStyles.active} ${commonStyles.rules}`}>
            <div className = {`${commonStyles.standart_background} ${commonStyles.container}`}></div>
            <div className = {`${commonStyles.hover_background} ${commonStyles.container}`}></div>
            <div className = {`${commonStyles.active_background} ${commonStyles.container}`}></div>
            <div className = {`${commonStyles.content_container} ${commonStyles.container}`}>
              <div className = {commonStyles.image_container}>
                <img src={cdnLoader('/images/rules/sbd.svg')} />
              </div>
              <div className = {commonStyles.text_container}>
                <h5 className = {commonStyles.rule_title}>{t('rules:basic')}</h5>
                <h6 className = {commonStyles.rule_description}>{t('rules:basic_text')}</h6>
              </div>
            </div>
          </div>
        </div>
        {availableRules.map(rule => {
          if (selectedGameMode.value === 'team' && rule.symbol === 'js') return
          if (selectedGameMode.value === 'solo' && (rule.symbol === 'cs' || rule.symbol === 'ccs')) return
          if (rule.symbol === 'ccs') return
          return (
            <div 
              className = {`${commonStyles.button_wrapper}`}
              onClick={() => {onRuleChange(rule.id)}}
              key={rule.id}
              >
              <div className  = {`${commonStyles.button_container} ${ruleIsChosen(rule.id) ? commonStyles.active : ''} ${commonStyles.rules}`}>
                <div className = {`${commonStyles.standart_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.hover_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.active_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.content_container} ${commonStyles.container}`}>
                  <div className = {commonStyles.image_container}>
                    <img src={cdnLoader(`/images/rules/${rule.symbol}.svg`)} />
                  </div>
                  <div className = {commonStyles.text_container}>
                    <h5 className = {commonStyles.rule_title}>{t(`rules:${GetRulesLangKeys(rule.symbol)}`) }</h5>
                    <h6 className = {commonStyles.rule_description}>{t(`rules:${GetRulesLangKeys(rule.symbol)}_text`)}</h6>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RulesStep
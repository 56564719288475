import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';

const VersusStep = (props) => {
  const {
    handleVersusSet,
    selectedVersus,
    currentStep
  } = props

  return (
    <div className = {`${commonStyles.buttons_container} ${commonStyles.players_container}`}>
      <div 
        className = {`${commonStyles.button_wrapper}`} 
        onClick = {() => {handleVersusSet({value: "2v2v2", label: "2vs2vs2"}, currentStep + 1)}}
        >
        <div className  = {`${commonStyles.button_container} ${selectedVersus === '2v2' ? commonStyles.active : ''} ${commonStyles.players_number}`}>
          <div className = {`${commonStyles.standart_background} ${commonStyles.container}`}></div>
          <div className = {`${commonStyles.hover_background} ${commonStyles.container}`}></div>
          <div className = {`${commonStyles.active_background} ${commonStyles.container}`}></div>
          <div className = {`${commonStyles.content_container} ${commonStyles.container}`}>
            <h5 className = {commonStyles.number_container}>2 vs 2</h5>
          </div>
        </div>
      </div>
      <div 
        className = {`${commonStyles.button_wrapper}`} 
        onClick = {() => {handleVersusSet({value: "3v3", label: "3vs3"}, currentStep + 1)}}
        >
        <div className  = {`${commonStyles.button_container} ${selectedVersus === '3v3' ? commonStyles.active : ''} ${commonStyles.players_number}`}>
          <div className = {`${commonStyles.standart_background} ${commonStyles.container}`}></div>
          <div className = {`${commonStyles.hover_background} ${commonStyles.container}`}></div>
          <div className = {`${commonStyles.active_background} ${commonStyles.container}`}></div>
          <div className = {`${commonStyles.content_container} ${commonStyles.container}`}>
            <h5 className = {commonStyles.number_container}>3 vs 3</h5>
          </div>
        </div>
      </div>
    </div>
  )
} 

export default VersusStep